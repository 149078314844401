import React from "react";
import newLogo from "../assets/images/333-removebg-preview.png";

// import footerlogo from "../assetsgeneral/images/footerlogo.png";
// import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      {/* <div className="footer-section" id="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="footer-logo">
                                <a href="/"> <img src={footerlogo} id="img-wa" style={{width: "80px"}} /> </a>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="footer-logo">
                                <ul>
                                    <li> <a href="https://www.instagram.com/phatthaise19?igsh=MWIzMHNvb2g5Ym1qcA=="> <i className="fa fa-instagram" aria-hidden="true"></i>  </a> </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="footer-logo-text">
                                <h2> Contact Us </h2>
                                <ul>
                                    <li> <a href="#"> <i className="fa fa-envelope" aria-hidden="true"></i> Info@phatthai.co.uk</a> </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="footer-end-se">
                                <p> Copyrights 2024 PhatThai. All Rights Reserved. Developed and Designed By 
                                    <a href="https://systemdecoding.com" target="_blank"> System Decoding</a> </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div> */}

      <footer class="footer">
        <div class="footer-top section">
          <div class="container">
            <div class="footer-brand">
              <a href="#">
                {/* <h1 className="logo">
              System
              <br />
              <span style={{ color: "orange" }}>Decoding</span>
            </h1> */}
                <img
                  className="logo"
                  src={newLogo}
                  alt=""
                  style={{ height: "90px" }}
                />
              </a>

              <p class="text">
                Bring Your Business Online.
                <br />
                Establish Your Virtual Presence.
              </p>

              <ul class="social-list">
                <li>
                  <a href="#" class="social-link">
                    <ion-icon name="logo-facebook"></ion-icon>
                  </a>
                </li>

                <li>
                  <a href="#" class="social-link">
                    <ion-icon name="logo-instagram"></ion-icon>
                  </a>
                </li>

                <li>
                  <a href="#" class="social-link">
                    <ion-icon name="logo-twitter"></ion-icon>
                  </a>
                </li>
              </ul>
            </div>

            <ul class="footer-list">
              <li>
                <p class="footer-list-title">Our links</p>
              </li>

              <li>
                <a href="#" class="footer-link">
                  Home
                </a>
              </li>

              <li>
                <a href="#about" class="footer-link">
                  About Us
                </a>
              </li>

              <li>
                <a href="#features" class="footer-link">
                  Features
                </a>
              </li>

              {/* <li>
                <a href="#" class="footer-link">
                  Team
                </a>
              </li> */}

              {/* <li>
                <a href="#" class="footer-link">
                  Blog
                </a>
              </li> */}
            </ul>

            <ul class="footer-list">
              <li>
                <p class="footer-list-title">Our Services</p>
              </li>

              <li>
                <a href="#services" class="footer-link">
                  ERP Solution
                </a>
              </li>

              <li>
                <a href="#services" class="footer-link">
                  SaaS Development
                </a>
              </li>

              <li>
                <a href="#services" class="footer-link">
                  Mobile App and Web Development
                </a>
              </li>

              <li>
                <a href="#services" class="footer-link">
                  Graphic Designing
                </a>
              </li>

              <li>
                <a href="#services" class="footer-link">
                  3D Rendering
                </a>
              </li>
            </ul>

            <ul class="footer-list">
              <li>
                <p class="footer-list-title">Other links</p>
              </li>

              <li>
                <a href="https://wa.me/923042006216" class="footer-link">
                  Discuss A Project
                </a>
              </li>

              <li>
                {/* <a href="#" class="footer-link">
                  Portfolio
                </a> */}
              </li>

              {/* <li>
                <a href="#" class="footer-link">
                  Privacy Policy
                </a>
              </li>

              <li>
                <a href="#" class="footer-link">
                  Terms & Conditions
                </a>
              </li> */}
              {/* 
              <li>
                <a href="#" class="footer-link">
                  Support
                </a>
              </li> */}
            </ul>

            <ul class="footer-list">
              <li>
                <p class="footer-list-title">Contact Us</p>
              </li>

              <li class="footer-item">
                <div class="footer-item-icon fa fa-phone">
                  {/* <ion-icon name="call"></ion-icon> */}
                </div>

                <div>
                  <a href="https://wa.me/923042006216" class="footer-item-link">
                    +923042006216
                  </a>
                  <a href="https://wa.me/923091203585" class="footer-item-link">
                    +923091203585
                  </a>
                </div>
              </li>

              {/* <li class="footer-item">
                <div class="footer-item-icon">
                  <ion-icon name="mail"></ion-icon>
                </div>

                <div>
                  <a href="mailto:info@desinic.com" class="footer-item-link">
                    info@desinic.com
                  </a>
                  <a href="mailto:help@desinic.com" class="footer-item-link">
                    help@desinic.com
                  </a>
                </div>
              </li> */}

              <li class="footer-item">
                <div class="footer-item-icon fa fa-map">
                  {/* <ion-icon name="location"></ion-icon> */}
                </div>

                <address class="footer-item-link">
                  Suit no# 401, Asia Pacific Tower, Karachi, Pakistan
                </address>
              </li>
            </ul>
          </div>
        </div>

        <div class="footer-bottom">
          <p class="copyright">
            &copy; 2024.{" "}
            {/* <a href="#" class="copyright-link">
              codewithsadee
            </a> */}
            All Rights Reserved
          </p>
        </div>
      </footer>
      {/* to go top */}
      {/* <a href="#top" class="go-top  active" aria-label="Go To Top" data-go-top>
        <ion-icon name="arrow-up-outline"></ion-icon>
      </a> */}
    </>
  );
};

export default Footer;
