import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../src/assetsgeneral/images/logo.png";
import { useSelector, useDispatch } from "react-redux";
import {
  tabHome,
  tabAboutUs,
  tabOurPlans,
  tabWeWork,
  tabContactUs,
} from "../../src/action/tabActions";
import img1 from "../assets/images/hero-banner.png";
import img2 from "../assets/images/about-banner.png";
import img3 from "../assets/images/feautres-banner.png";
import img4 from "../assets/images/blog-1.jpg";
import img5 from "../assets/images/blog-2.jpg";
import img6 from "../assets/images/blog-3.jpg";
import img7 from "../assets/images/blog-4.jpg";
import img8 from "../assets/images/1.png";
import img9 from "../assets/images/2.png";
import img10 from "../assets/images/3.png";
import img11 from "../assets/images/4.png";
import img12 from "../assets/images/5.png";
import img13 from "../assets/images/6.png";
import newLogo from "../assets/images/333-removebg-preview.png";

const NavBar = () => {
  const myState = useSelector((state) => state.changeTheTab);
  const scrollToAboutUs = () => {
    const aboutUsSection = document.getElementById("about-us-section");
    if (aboutUsSection) {
      aboutUsSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToFooter = () => {
    const footerSection = document.getElementById("footer");
    if (footerSection) {
      footerSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  console.log("***********", myState.viewNavBar);
  const dispatch = useDispatch();

  const [showDropDown, setshowDropDown] = useState(false);
  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
  };
  console.log(showDropDown);

  return (
    <>
      {/* <div className="">
                <div className="container">
                    <div className="row">   
                        <div className="col-md-12">
                            <div className="riyo-header-img">
                                <nav className="navbar sticky-top navbar-expand-lg bg-dark">
                                    <div className="container">
                                        <NavLink className="navbar-brand" to="/"><img src={logo} id="logsedf" style={{height: "95px", marginLeft: "75px"}}/></NavLink>
                                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                            <i className="fa fa-bars" aria-hidden="true"></i>
                                        </button>
                                        <div className="collapse navbar-collapse" id="navbarSupportedContent"> */}
      {/* {
                                            myState.viewNavBar === "aboutus" ?
                                            <>
                                            <ul className="navbar-nav">
                                                                                            
                                                <li className="nav-item">
                                                    <NavLink className="nav-link" onClick={tabAboutUs} id="home-col" to="#about-us">About Us</NavLink>
                                                </li>
                                                
                                                <li className="nav-item">
                                                    <NavLink className="nav-link" onClick={tabContactUs} to="#contact">Contact Us</NavLink>
                                                </li>
                                                
                                            </ul>
                                            </>      
                                            : 
                                            myState.viewNavBar === "contactus" ?
                                            <>
                                            <ul className="navbar-nav">
                                                
                                                <li className="nav-item">
                                                    <NavLink className="nav-link" onClick={tabAboutUs} to="#about-us">About Us</NavLink>
                                                </li>
                                                
                                                <li className="nav-item">
                                                    <NavLink className="nav-link" onClick={tabContactUs} id="home-col"  to="#contact">Contact Us</NavLink>
                                                </li>
                                                
                                            </ul>
                                            </>      
                                            : 
                                           <div>Loading</div>
                                        } */}
      {/* {myState.viewNavBar === "aboutus" ? (
                                            <>
                                            <ul className="navbar-nav">
                                                <li className="nav-item">
                                                <NavLink className="nav-link" onClick={scrollToAboutUs} id="home-col" to="#about-us">About Us</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                <NavLink className="nav-link" onClick={scrollToFooter} to="#contact">Contact Us</NavLink>
                                                </li>
                                            </ul>
                                            </>
                                        ) : myState.viewNavBar === "contactus" ? (
                                            <>
                                            <ul className="navbar-nav">
                                                <li className="nav-item">
                                                <NavLink className="nav-link" onClick={scrollToAboutUs} to="#about-us">About Us</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                <NavLink className="nav-link" onClick={scrollToFooter} id="home-col" to="#contact">Contact Us</NavLink>
                                                </li>
                                            </ul>
                                            </>
                                        ) : (
                                            <div>Loading</div>
                                        )}
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
      <header className="header" data-header>
        <div className="container">
          <div className="overlay" data-overlay></div>

          <a href="#">
            {/* <h1 className="logo">
              System
              <br />
              <span style={{ color: "orange" }}>Decoding</span>
            </h1> */}
            <img
              className="logo"
              src={newLogo}
              alt=""
              style={{ height: "90px" }}
            />
          </a>

          <nav className="navbar" data-navbar>
            <div className="navbar-top">
              <a href="#" className="logo">
                System Decoding
              </a>

              <button
                className="nav-close-btn"
                aria-label="Close Menu"
                data-nav-close-btn
              >
                <ion-icon name="close-outline"></ion-icon>
              </button>
            </div>

            <ul className="navbar-list">
              <li className="navbar-item">
                <a href="#home" className="navbar-link" data-navbar-link>
                  Home
                </a>
              </li>

              <li className="navbar-item">
                <a href="#about" className="navbar-link" data-navbar-link>
                  About
                </a>
              </li>

              <li className="navbar-item">
                <a href="#services" className="navbar-link" data-navbar-link>
                  Services
                </a>
              </li>

              <li className="navbar-item">
                <a href="#features" className="navbar-link" data-navbar-link>
                  Features
                </a>
              </li>

              {/* <li className="navbar-item">
                <a href="#blog" className="navbar-link" data-navbar-link>
                  Blog
                </a>
              </li> */}

              <li className="navbar-item">
                <a href="#" className="navbar-link" data-navbar-link>
                  Contact Us
                </a>
              </li>
            </ul>
          </nav>

          <a href="https://wa.me/923042006216" className="btn">
            <ion-icon
              name="chevron-forward-outline"
              aria-hidden="true"
            ></ion-icon>

            <span>Discuss A Project</span>
          </a>

          <button
            className="nav-open-btn"
            aria-label="Open Menu"
            data-nav-open-btn
          >
            <ion-icon name="menu-outline"></ion-icon>
          </button>
        </div>
      </header>
      <main>
        <article>
          {/* <!-- 
        - #HERO
      --> */}

          <section className="hero" id="home">
            <div className="container">
              <div className="hero-content">
                <p className="hero-subtitle stylish-text">
                  Bring Your Business Online.
                </p>

                <div className="h2 hero-title " style={{ color: "orange" }}>
                  We are a Creative Digital Agency
                </div>

                <p className="hero-text">
                  Specializing in web development and mobile app development to
                  bring your business online. Additionally, we empower startups
                  with our innovative technology, assisting entrepreneurs in
                  innovating their businesses through our community of
                  innovators.
                </p>

                {/* <button className="btn">Learn More</button> */}
              </div>

              <figure className="hero-banner">
                <img
                  src={img1}
                  width="694"
                  height="529"
                  loading="lazy"
                  alt="hero-banner"
                  className="w-100 banner-animation"
                />
              </figure>
            </div>
          </section>

          {/* <!-- 
        - #ABOUT
      --> */}

          <section className="section about" id="about">
            <div className="container">
              <figure className="about-banner">
                <img
                  src={img2}
                  width="700"
                  height="532"
                  loading="lazy"
                  alt="about banner"
                  className="w-100 banner-animation"
                />
              </figure>

              <div className="about-content">
                <h2 className="h2 section-title underline">Why Our Agency</h2>

                <p className="about-text">
                  {/* Why Choose Us? */}
                  At System Decoding, we are dedicated to delivering exceptional
                  results that exceed our clients' expectations. Our team of
                  experienced professionals brings creativity, expertise, and a
                  client-focused approach to every project. Here’s why you
                  should choose us:
                </p>

                <p className="about-text">
                  <h4>Expert Team:</h4> Our team is composed of industry experts
                  with a wealth of experience in various fields of technology.
                  <h4>Innovative Solutions:</h4> We stay ahead of the curve by
                  incorporating the latest trends and technologies into our
                  projects.
                  <h4>Client-Centered Approach:</h4> We prioritize our clients’
                  needs and work closely with them to bring their visions to
                  life.
                  {/* <h4>Proven Track Record:</h4> Our portfolio showcases a
                  variety of successful projects that demonstrate our ability to
                  deliver high-quality solutions.
                  <h4>Commitment to Excellence:</h4> We are committed to
                  delivering excellence in every aspect of our work, ensuring
                  that our clients receive the best possible outcomes. */}
                </p>
                <div className="row">
                  <ul className="stats-list">
                    <li className="stats-card">
                      <p className="h3 stats-title">25+</p>

                      <p className="stats-text">Satisfied Clients</p>
                    </li>

                    <li className="stats-card">
                      <p className="h3 stats-title">70+</p>

                      <p className="stats-text">Project Launched</p>
                    </li>

                    <li className="stats-card">
                      <p className="h3 stats-title">4</p>

                      <p className="stats-text">Years Completed</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          {/* 
      <!-- 
        - #SERVICE
      --> */}

          <section className="section service" id="services">
            <div className="container">
              <h2 className="h2 section-title underline">Our Specialization</h2>
              <div
                className="my-5"
                style={{ display: "flex", justifyContent: "center" }}
              >
                We offer a wide range of services to meet the diverse needs of
                our clients. Our expertise spans across:
              </div>
              <div className="row">
                <ul className="service-list">
                  <li>
                    <div className="service-card">
                      <div className="card-icon">
                        <img src={img8} alt="" style={{ height: "130px" }} />
                        {/* <i className="fa fa-wpexplorer" aria-hidden="true"></i> */}
                        {/* <ion-icon name="telescope-outline"></ion-icon> */}
                      </div>

                      <h3 className="h3 title">ERP Solution</h3>

                      <p className="text">
                        Streamlining business processes and integrating data
                        across your organization with customized ERP solutions.
                      </p>
                      {/* <h3 className="h3 title">Strategy & Research</h3>

                    <p className="text">
                      Conducting in-depth research and developing strategic
                      plans to guide your business towards success.
                    </p> */}

                      {/* <button className="card-btn" aria-label="Show More">
                      <ion-icon name="chevron-forward-outline"></ion-icon>
                    </button> */}
                    </div>
                  </li>

                  <li>
                    <div className="service-card">
                      <div className="card-icon">
                        {/* <i className="ion-icon ion-desktop-outline"></i> */}
                        <img src={img9} alt="" style={{ height: "130px" }} />
                      </div>

                      <h3 className="h3 title">Web Development</h3>

                      <p className="text">
                        Developing responsive and user-friendly websites that
                        enhance your online presence and help grow your
                        business.
                        {/* Developing responsive & user-friendly websites (whether
                      it's a landing page, eCommerce site, or business website)
                      to enhance your online presence & grow your business */}
                      </p>

                      {/* <button className="card-btn" aria-label="Show More">
                      <ion-icon name="chevron-forward-outline"></ion-icon>
                    </button> */}
                    </div>
                  </li>

                  <li>
                    <div className="service-card">
                      <div className="card-icon">
                        <img src={img10} alt="" style={{ height: "130px" }} />

                        {/* <ion-icon name="globe-outline"></ion-icon> */}
                      </div>

                      <h3 className="h3 title">SaaS Development</h3>

                      <p className="text">
                        Building scalable and secure Software as a Service
                        (SaaS) solutions to streamline your business operations.
                      </p>

                      {/* <button className="card-btn" aria-label="Show More">
                      <ion-icon name="chevron-forward-outline"></ion-icon>
                    </button> */}
                    </div>
                  </li>
                  <li>
                    <div className="service-card">
                      <div className="card-icon">
                        <img src={img11} alt="" style={{ height: "130px" }} />

                        {/* <ion-icon name="globe-outline"></ion-icon> */}
                      </div>

                      <h3 className="h3 title">
                        Mobile App Development (Android and iOS)
                      </h3>

                      <p className="text">
                        Crafting intuitive and engaging mobile applications
                        tailored to your business needs.
                      </p>

                      {/* <button className="card-btn" aria-label="Show More">
                      <ion-icon name="chevron-forward-outline"></ion-icon>
                    </button> */}
                    </div>
                  </li>
                  <li>
                    <div className="service-card">
                      <div className="card-icon">
                        <img src={img12} alt="" style={{ height: "130px" }} />

                        <ion-icon name="telescope-outline"></ion-icon>
                      </div>

                      <h3 className="h3 title">Graphic Designing</h3>

                      <p className="text">
                        Creating captivating motion graphics, logos and UI/UX
                        experiences that enhance your brand's storytelling and
                        marketing efforts.
                      </p>

                      {/* <button className="card-btn" aria-label="Show More">
                      <ion-icon name="chevron-forward-outline"></ion-icon>
                    </button> */}
                    </div>
                  </li>

                  <li>
                    <div className="service-card">
                      <div className="card-icon">
                        <img src={img13} alt="" style={{ height: "130px" }} />

                        {/* <ion-icon name="desktop-outline"></ion-icon> */}
                      </div>

                      <h3 className="h3 title">3D Rendering</h3>

                      <p className="text">
                        Providing high-quality 3D rendering services and Unreal
                        Engine models to bring your concepts and designs to
                        life.
                      </p>

                      {/* <button className="card-btn" aria-label="Show More">
                      <ion-icon name="chevron-forward-outline"></ion-icon>
                    </button> */}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </section>

          {/* <!-- 
        - #FEATURES
      --> */}

          <section className="section features" id="features">
            <div className="container">
              <h2 className="h2 section-title underline">Our Features</h2>

              <ul className="features-list">
                {/* <li> */}
                {/* <div className="features-card"> */}
                {/* <div className="icon fa fa-lightbulb-o"> */}
                {/* <ion-icon name="bulb-outline"></ion-icon> */}
                {/* </div>
                    <div className="content">
                      <h3 className="h3 title">Idea & Analysis</h3>

                      <p className="text">
                        Conducting thorough research and analysis to transform
                        your ideas into viable projects.
                      </p>
                    </div>
                  </div>
                </li> */}

                <li>
                  <div className="features-card">
                    <div className="icon fa fa-cloud"></div>
                    <div className="content">
                      <h3 className="h3 title">Idea & Analysis</h3>

                      <p className="text">
                        Conducting thorough research and analysis to transform
                        your ideas into viable projects.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="features-card">
                    <div className="icon fa fa-paint-brush"></div>
                    <div className="content">
                      <h3 className="h3 title">Designing</h3>

                      <p className="text">
                        Crafting visually appealing and user-centric designs
                        that align with your brand identity.
                      </p>
                    </div>
                  </div>
                </li>
              </ul>

              <figure className="features-banner">
                <img
                  src={img3}
                  width="369"
                  height="318"
                  loading="lazy"
                  alt="Features Banner"
                  className="w-100 banner-animation"
                />
              </figure>

              <ul className="features-list">
                <li>
                  <div className="features-card">
                    <div className="icon fa fa-code">
                      {/* <ion-icon name="code-slash-outline"></ion-icon> */}
                    </div>
                    <div className="content">
                      <h3 className="h3 title">Development</h3>

                      <p className="text">
                        Implementing robust and efficient development practices
                        to bring your project to life.
                      </p>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="features-card">
                    <div className="icon fa fa-rocket">
                      {/* <ion-icon name="rocket-outline"></ion-icon> */}
                    </div>

                    <div className="content">
                      <h3 className="h3 title">Testing & Launching</h3>

                      <p className="text">
                        Performing rigorous testing to ensure the highest
                        quality before launching your project.
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </section>

          {/* 
      <!-- 
        - #BLOG
      --> */}

          {/* <section className="section blog" id="blog">
            <div className="container">
              <h2 className="h2 section-title underline">Our Blog & News</h2>

              <ul className="blog-list">
                <li>
                  <div className="blog-card">
                    <figure className="banner">
                      <a href="#">
                        <img
                          src={img4}
                          width="750"
                          height="350"
                          loading="lazy"
                          alt="Vestibulum massa arcu, consectetu pellentesque scelerisque."
                          className="img-cover"
                        />
                      </a>
                    </figure>

                    <div className="content">
                      <h3 className="h3 title">
                        <a href="#">
                          Vestibulum massa arcu, consectetu pellentesque
                          scelerisque.
                        </a>
                      </h3>

                      <p className="text">
                        Sed quis sagittis velit. Aliquam velit eros, bibendum ut
                        massa et, consequat laoreet erat nam ac imperdiet.
                      </p>

                      <div className="meta">
                        <div className="publish-date">
                          <ion-icon name="time-outline"></ion-icon>

                          <time datetime="2022-03-07">7 March, 2022</time>
                        </div>

                        <button className="comment" aria-label="Comment">
                          <ion-icon name="chatbubble-outline"></ion-icon>

                          <data value="15">15</data>
                        </button>

                        <button className="share" aria-label="Share">
                          <ion-icon name="share-social-outline"></ion-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="blog-card">
                    <figure className="banner">
                      <a href="#">
                        <img
                          src={img5}
                          width="750"
                          height="350"
                          loading="lazy"
                          alt="Quisque egestas iaculis felis eget placerat ut pulvinar mi."
                          className="img-cover"
                        />
                      </a>
                    </figure>

                    <div className="content">
                      <h3 className="h3 title">
                        <a href="#">
                          Quisque egestas iaculis felis eget placerat ut
                          pulvinar mi.
                        </a>
                      </h3>

                      <p className="text">
                        Sed quis sagittis velit. Aliquam velit eros, bibendum ut
                        massa et, consequat laoreet erat nam ac imperdiet.
                      </p>

                      <div className="meta">
                        <div className="publish-date">
                          <ion-icon name="time-outline"></ion-icon>

                          <time datetime="2022-03-07">7 March, 2022</time>
                        </div>

                        <button className="comment" aria-label="Comment">
                          <ion-icon name="chatbubble-outline"></ion-icon>

                          <data value="15">15</data>
                        </button>

                        <button className="share" aria-label="Share">
                          <ion-icon name="share-social-outline"></ion-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="blog-card">
                    <figure className="banner">
                      <a href="#">
                        <img
                          src={img6}
                          width="750"
                          height="350"
                          loading="lazy"
                          alt="Fusce sem ligula, imperdiet sed nisi sit amet, euismod posuere."
                          className="img-cover"
                        />
                      </a>
                    </figure>

                    <div className="content">
                      <h3 className="h3 title">
                        <a href="#">
                          Fusce sem ligula, imperdiet sed nisi sit amet, euismod
                          posuere.
                        </a>
                      </h3>

                      <p className="text">
                        Sed quis sagittis velit. Aliquam velit eros, bibendum ut
                        massa et, consequat laoreet erat nam ac imperdiet.
                      </p>

                      <div className="meta">
                        <div className="publish-date">
                          <ion-icon name="time-outline"></ion-icon>

                          <time datetime="2022-03-07">7 March, 2022</time>
                        </div>

                        <button className="comment" aria-label="Comment">
                          <ion-icon name="chatbubble-outline"></ion-icon>

                          <data value="15">15</data>
                        </button>

                        <button className="share" aria-label="Share">
                          <ion-icon name="share-social-outline"></ion-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="blog-card">
                    <figure className="banner">
                      <a href="#">
                        <img
                          src={img7}
                          width="750"
                          height="350"
                          loading="lazy"
                          alt="Donec feugiat mollis nisi in dignissim. Morbi sollicitudin quis."
                          className="img-cover"
                        />
                      </a>
                    </figure>

                    <div className="content">
                      <h3 className="h3 title">
                        <a href="#">
                          Donec feugiat mollis nisi in dignissim. Morbi
                          sollicitudin quis.
                        </a>
                      </h3>

                      <p className="text">
                        Sed quis sagittis velit. Aliquam velit eros, bibendum ut
                        massa et, consequat laoreet erat nam ac imperdiet.
                      </p>

                      <div className="meta">
                        <div className="publish-date">
                          <ion-icon name="time-outline"></ion-icon>

                          <time datetime="2022-03-07">7 March, 2022</time>
                        </div>

                        <button className="comment" aria-label="Comment">
                          <ion-icon name="chatbubble-outline"></ion-icon>

                          <data value="15">15</data>
                        </button>

                        <button className="share" aria-label="Share">
                          <ion-icon name="share-social-outline"></ion-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </section> */}
        </article>
      </main>
    </>
  );
};
export default NavBar;
