import React from "react";
import Banner from "../components/Banner";
import Features from "../components/Features";
import Footer from "../components/Footer";
import Advertiser from "../components/homeComponents/Advertiser";
import OurSolutions from "../components/homeComponents/OurSolutions";
import Review from "../components/homeComponents/Review";
import NavBar from "../components/NavBar";
import Pricing from "../components/Pricing";

const Home = () => {
  return (
    <>
      {/* <section> */}
      <NavBar />

      <Footer />
      {/* </section> */}
    </>
  );
};
export default Home;
